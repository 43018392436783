define("clients/routes/login", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin", "clients/mixins/css-class-namespace", "clients/config/environment"], function (_exports, _unauthenticatedRouteMixin, _cssClassNamespace, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, _cssClassNamespace.default, {
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),

    model() {
      var urlParams = new URLSearchParams(window.location.search);

      if (urlParams.get('client_number')) {
        return Ember.RSVP.hash({
          adminMode: true,
          adminToken: urlParams.get('admin_token'),
          adminEmail: urlParams.get('admin_email'),
          clientNumber: urlParams.get('client_number'),
          clientFirstName: urlParams.get('client_first_name'),
          clientLastName: urlParams.get('client_last_name'),
          redirectRoute: urlParams.get('redirect_route'),
          missionId: urlParams.get('mission_id'),
          account: this.sessionAccount.account
        });
      }

      return {
        identification: null,
        adminMode: false
      };
    },

    setupController(controller, model) {
      this._super(controller, model);

      if (model.adminMode) {
        this.session.authenticate('authenticator:adminPretender', model.clientNumber, model.clientFirstName, model.clientLastName, model.missionId);
      } else {
        this.logout();
        this.login();
      }
    },

    login() {
      // Check out the docs for all the options:
      // https://auth0.com/docs/libraries/auth0js/v9#webauth-authorize-
      const authOptions = {
        audience: _environment.default['ember-simple-auth'].auth0.audience,
        redirectUri: _environment.default['ember-simple-auth'].auth0.redirectUri,
        responseType: 'token id_token',
        scope: 'openid email',
        state: this._router.url
      };
      this.session.authenticate('authenticator:auth0-universal', authOptions, err => {
        if (err) {
          console.log("Auth0 err", err);
        }
      });
    },

    logout() {
      localStorage.clear();

      try {
        this.session.invalidate();
      } catch (e) {
        console.log('Invalidate session error:', e);
      }
    },

    actions: {
      willTransition() {
        return this.controllerFor('application').set('showNavBar', false);
      }

    }
  });

  _exports.default = _default;
});