define("clients/components/admin-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AdminNotificationComponent;
  AdminNotificationComponent = Ember.Component.extend({
    session: Ember.inject.service(),
    userName: Ember.computed('session.data.authenticated', function () {
      var adminDetails;
      adminDetails = this.get('session.data.authenticated.idTokenPayload.adminDetails');
      return adminDetails.clientFirstName + " " + adminDetails.clientLastName;
    }),
    actions: {
      adminLogout: function () {
        return this.get('session').invalidate().then(function () {
          return open(location, '_self').close();
        });
      }
    }
  });
  var _default = AdminNotificationComponent;
  _exports.default = _default;
});