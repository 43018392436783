define("clients/routes/application", ["exports", "clients/config/environment", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth-auth0/mixins/application-route-mixin", "@chamaeleonidae/chmln"], function (_exports, _environment, _applicationRouteMixin, _applicationRouteMixin2, _chmln) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_environment.default['ember-simple-auth'].auth0.enabled ? _applicationRouteMixin2.default : _applicationRouteMixin.default, {
    auth0: Ember.inject.service(),
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    rollbar: Ember.inject.service(),
    model: function () {
      if (_environment.default.chameleonProjectKey && this.session.isAuthenticated) {
        return this.sessionAccount.account.then(clientUser => {
          // Wait for the account promise to resolve
          const chameleonData = {
            name: `${clientUser.first_name} ${clientUser.last_name}`,
            // Use clientUser from the resolved account
            first_name: clientUser.first_name,
            last_name: clientUser.last_name,
            email: clientUser.email,
            id: clientUser.id,
            role: 'Client',
            appId: 'client-web'
          };

          _chmln.default.identify(clientUser.id, chameleonData);
        });
      } else {
        console.warn('User is not authenticated, skipping Chameleon identification.');
      }

      return Ember.RSVP.hash({
        account: this.sessionAccount.account
      });
    },
    _invalidateIfAuthenticated: function () {
      if (this.session.data.authenticated.authenticator != "authenticator:adminPretender") {
        this._super();
      }
    },
    // only needed for devise based auth - can remove when we release auth0
    sessionAuthenticated: function () {},
    actions: {
      loginSuccess: function () {
        let nextRoute = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
        this.sessionAccount.account.then(account => {
          this.controllerFor('application').set('model.account', account); // eslint-disable-next-line no-undef

          this.controllerFor('application').set('canManageInsightsUsers', sessionAccount.canManageInsightsUsers);
          this.set('rollbar.currentUser', {
            email: account.get('email'),
            id: account.id
          });
        });
        this.refresh();

        if (nextRoute) {
          return this.transitionTo(nextRoute);
        } else {
          return this.send('goToMissionList');
        }
      },
      error: function (error) {
        console.log('error', error);

        if (error?.name && error.name == 'invalid_token') {
          this.auth0.logout();
        } else {
          Ember.run.later(() => {
            this.transitionTo('fourOhFour', {});
          });
        }
      },
      closeInsightsBanner: function () {
        let client = this.get('controller.model.account');
        client.set('feature_flags.show_insights_banner', false);
        client.save();
        this.controller.set('showInsightsBanner', false);
      },
      goToInsights: function () {
        this.transitionTo('insights');
      },
      goToMissionList: function () {
        const client = this.get('controller.model.account');

        if (client.is_insights_buildings) {
          const insight_user_management_url = `${_environment.default.insights_endpoint}/inspections?iorder=desc&ipage=1&isize=25&isortby=inspectionStart&vid=${client.organization.id}`;
          window.location = insight_user_management_url;
        } else {
          return this.transitionTo('dashboard.locations.index');
        }
      },
      goToInsightsUserManagment: function () {
        const insight_user_management_url = `${_environment.default.insights_endpoint}/users?origin=sites&usrsize=25&usrpage=1&usrorder=asc&usrsortby=name`;
        window.location = insight_user_management_url;
      },
      contactAboutInsights: function () {
        window.open('https://dronebase.com/insights-roof-reports#roof-report-pricing');
      }
    }
  });

  _exports.default = _default;
});