define("clients/authenticators/admin_pretender", ["exports", "ember-simple-auth/authenticators/devise", "clients/config/environment"], function (_exports, _devise, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AdminPretenderAuthenticator;
  AdminPretenderAuthenticator = _devise.default.extend({
    resourceName: 'client',
    session: Ember.inject.service(),
    authenticate: function (clientNumber, clientFirstName, clientLastName, missionId) {
      var authOptions, state;

      if (missionId) {
        state = '/orders/' + missionId;
      } else {
        state = 'login';
      }

      authOptions = {
        audience: _environment.default['ember-simple-auth'].auth0.audience,
        redirectUri: _environment.default['ember-simple-auth'].auth0.redirectUri,
        responseType: 'token id_token',
        scope: 'openid email',
        state: state,
        is_admin_mode: true,
        client_params: {
          first_name: clientFirstName,
          last_name: clientLastName,
          number: clientNumber
        }
      };
      return this.get('session').authenticate('authenticator:auth0-universal', authOptions, function (err) {
        if (err) {
          return console.log("Auth0 err", err);
        }
      });
    }
  });
  var _default = AdminPretenderAuthenticator;
  _exports.default = _default;
});