define("clients/mixins/inner-application-route-setup-controller-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var InnerApplicationRouteSetupControllerMixin;
  InnerApplicationRouteSetupControllerMixin = Ember.Mixin.create({
    session: Ember.inject.service(),
    setupController: function (controller, model) {
      this._super(controller, model);

      this.controllerFor('application').set('adminMode', this.get('sessionAccount.adminMode'));
      this.controllerFor('application').set('showNavBar', true);
      return this.controllerFor('application').set('minimizedSidebar', false);
    }
  });
  var _default = InnerApplicationRouteSetupControllerMixin;
  _exports.default = _default;
});