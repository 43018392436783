define("clients/routes/authentication-callback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    auth0: Ember.inject.service(),
    metrics: Ember.inject.service(),

    model() {
      const state = this.get('session').get('data.authenticated.state');
      const clientNumber = this.get('session.data.authenticated.idTokenPayload.adminDetails.clientNumber');
      const isDroneBase = this.get('session.data.authenticated.idTokenPayload.appMetadata.isDroneBase');

      if (clientNumber && !isDroneBase) {
        this.metrics.trackEvent({
          event: 'event',
          category: 'Authentication',
          action: 'click',
          label: 'Logout'
        });
        this.auth0.logout();
      } else {
        if (state === '/login') {
          return this.transitionTo('/locations'); // get to the locations index list
        } else {
          return this.transitionTo(state); // get back to where you came from
        }
      }
    }

  });

  _exports.default = _default;
});