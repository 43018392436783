define("clients/routes/order-flow", ["exports", "clients/config/environment", "clients/mixins/css-class-namespace", "clients/mixins/inner-application-route-setup-controller-mixin", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _environment, _cssClassNamespace, _innerApplicationRouteSetupControllerMixin, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MultiStepOrderFlowRoute;
  MultiStepOrderFlowRoute = Ember.Route.extend(_authenticatedRouteMixin.default, _cssClassNamespace.default, _innerApplicationRouteSetupControllerMixin.default, {
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    queryParams: {
      upsellAddonId: {
        refreshModel: true
      }
    },
    model: function (params) {
      var mission;
      mission = this.store.createRecord('mission');

      if (!mission.get('onsite_contact')) {
        this.store.createRecord('onsite_contact', {
          mission: mission,
          call_action: 'not_needed'
        });
      }

      if (!mission.get('scheduling_contact')) {
        this.store.createRecord('scheduling_contact', {
          mission: mission
        });
      }

      return Ember.RSVP.hash({
        mission: mission,
        client: this.get('sessionAccount.account'),
        orderFlow: 'standard',
        packages: this.get('store').query('package', {
          available: true,
          client_token: this.get('session.data.authenticated.token'),
          client_number: this.get('session.data.authenticated.idTokenPayload.adminDetails.clientNumber'),
          client_id: this.get('session.data.authenticated.profile.sub')
        }).then(function (response) {
          return response.sortBy('vertical.short_name', 'name', 'price');
        }),
        location: this.store.peekAll('location').findBy('temp', true) || this.store.createRecord('location'),
        params: params,
        package_addons: this.store.findAll('package-addon'),
        upsellAddonId: params.upsellAddonId,
        features: Ember.A([]),
        watchers: null
      });
    },
    setupController: function (controller, model) {
      this._super(controller, model);

      this.controller.set('orderStep', 0);

      if (this.get('sessionAccount.account')) {
        return this.get('sessionAccount.account').then(function (_this) {
          return function (client) {
            _this.controllerFor('application').set('showInsightsBanner', false);

            _this.controllerFor('application').set('canManageInsightsUsers', _this.sessionAccount.canManageInsightsUsers);

            return _this.controller.set('clientHasComposerReports', client.get('has_composer_reports'));
          };
        }(this));
      }
    },
    actions: {
      update: function (model) {
        var _this;

        _this = this;
        return model.location.save().then(function () {
          return _this.transitionTo('missions.show', model.mission.id);
        });
      }
    }
  });
  var _default = MultiStepOrderFlowRoute;
  _exports.default = _default;
});