define("clients/mixins/google-map-features-mixin", ["exports", "ember-changeset-validations", "ember-changeset", "clients/validations/map-polygon", "clients/validations/map-panorama", "clients/validations/map-point", "clients/mixins/google-map-init-mixin", "clients/data/map_features", "jquery", "lodash/function", "lodash/cloneDeep", "clients/config/environment", "clients/helpers/DBMap/format-length", "clients/helpers/DBMap/format-area", "clients/helpers/DBMap/format-altitude"], function (_exports, _emberChangesetValidations, _emberChangeset, _mapPolygon, _mapPanorama, _mapPoint, _googleMapInitMixin, _map_features, _jquery, _function2, _cloneDeep2, _environment, _formatLength, _formatArea, _formatAltitude) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const GoogleMapFeaturesMixin = Ember.Mixin.create(_googleMapInitMixin.default, {
    sessionAccount: Ember.inject.service(),
    metrics: Ember.inject.service(),
    geoJsonConverter: Ember.inject.service(),
    colorIndex: 0,
    selectedFeatureId: null,
    selectedFeature: Ember.computed('model.features.[]', 'selectedFeatureId', function () {
      return this.model.features.findBy('id', this.selectedFeatureId);
    }),
    errors: Ember.A([]),
    AREA_OUTSIDE_OF_RANGE_ERROR: 'area_outside_of_defined_range_error',
    featurePresenceObserver: Ember.observer('model.features.length', function () {
      let newErrors = this.errors;
      const polygonsCount = this.model.features.filter(feature => feature.object.getGeometry().getType() === 'Polygon').length;
      const panosCount = this.model.features.filter(feature => feature.feature_type === 'panorama').length;

      if (polygonsCount > 0) {
        newErrors = newErrors.filter(error => error.key !== 'absent_polygon');
      }

      if (panosCount > 0) {
        newErrors = newErrors.filter(error => error.key !== 'absent_panorama');
      }

      this.set('errors', newErrors);
    }),
    featureErrorsObserver: Ember.observer('model.features.{length,@each.errors.[]}', function () {
      let newErrors = this.errors.filter(error => !error.key.match(/^feature#/));
      this.model.features.forEach(feature => {
        if (feature.errors.length === 0) {
          return;
        }

        const featureErrors = feature.errors.map(error => error.validation.join('.')).join(' ');
        newErrors.addObject({
          key: `feature#${feature.id}`,
          message: `${feature.name} has some errors: ${featureErrors}`
        });
      });
      this.set('errors', newErrors);
    }),
    isAreaOutsideOfRange: Ember.computed('model.features.{length,@each.areaOutsideOfRange}', function () {
      return this.model.features.some(feature => feature.areaOutsideOfRange);
    }),
    isCalculateFeaturePrice: Ember.computed.reads('model.mission.package.hasCatalogItems'),
    totalArea: Ember.computed('model.features.@each.area', function () {
      return this.model.features.reduce((totalArea, feature) => {
        return totalArea + (feature.area ? parseFloat(feature.area) : 0);
      }, 0);
    }),
    totalPrice: Ember.computed('model.features.@each.price', function () {
      return this.model.features.reduce((totalPrice, feature) => totalPrice + feature.price, 0);
    }),
    totalPriceProcessing: Ember.computed('model.features.@each.isProcessing', function () {
      return this.model.features.any(feature => feature.isProcessing);
    }),

    init() {
      this._super();

      this.set('model.features', Ember.A([]));

      const _mapFeatureData = (0, _cloneDeep2.default)(_map_features.default);

      this.mapStyles = _mapFeatureData.mapStylesData;
      this.featureColors = _mapFeatureData.featureColors;
      this.featureOptions = _mapFeatureData.featureOptions;
      this.selectedFeatureOptions = _mapFeatureData.selectedFeatureOptions;
      this.disabledFeatureOptions = _mapFeatureData.disabledFeatureOptions;
    },

    setupListeners() {
      this.feature = Ember.Object.extend({
        name: '',
        notes: '',
        catalog_item_ids: Ember.A([]),
        errors: Ember.A([]),
        isProcessing: false,
        price: null,
        areaOutsideOfRange: false
      });
      this.map.data.addListener('addfeature', event => {
        const featureType = event.feature.getProperty('feature_type');
        this.setGeometryData(event.feature);
        const featureOptions = this.lookUpMapOptions(event.feature);
        this.map.data.overrideStyle(event.feature, featureOptions.defaultOptions);
        this.map.data.overrideStyle(event.feature, {
          fillColor: featureOptions.mapColor,
          strokeColor: featureOptions.mapColor
        });
        const newFeature = this.feature.create({
          id: event.feature.getId(),
          object: event.feature,
          feature_type: featureType,
          feature_index: event.feature.getProperty('feature_index') || this.getNextIndex(featureType),
          area: event.feature.getProperty('area'),
          lineLength: event.feature.getProperty('lineLength'),
          name: event.feature.getProperty('name'),
          notes: event.feature.getProperty('notes'),
          no_fly_zone: event.feature.getProperty('no_fly_zone'),
          color: featureOptions.keyColor,
          iconName: featureOptions.iconName,
          defaultName: featureOptions.defaultName,
          catalogEnabled: featureOptions.catalogEnabled,
          catalog_item_ids: event.feature.getProperty('catalog_item_ids'),
          shot_type_ids: event.feature.getProperty('shot_type_ids'),
          shot_type_attributes: event.feature.getProperty('shot_type_attributes')
        });
        this.model.features.pushObject(newFeature);
        this.enableFeature(newFeature);
        this.saveData();
      });
      this.map.data.addListener('click', event => {
        const feature = this.model.features.findBy('id', event.feature.getId());
        this.enableFeature(feature);
      });
      this.map.data.addListener('mousedown', () => {
        this.infoWindow.close();
      });
      this.map.data.addListener('setproperty', event => {
        if (event.newValue === event.oldValue) {
          return;
        }

        const feature = this.model.features.findBy('object', event.feature);

        if (feature) {
          feature.set(event.name, event.newValue);
        }
      });
      this.map.data.addListener('setgeometry', _function2.default.debounce(event => {
        this.setGeometryData(event.feature);
        this.saveData();
      }, 1000));
      this.map.addListener('click', () => {
        this.disableFeature(this.selectedFeature);
      });
    },

    setupInfoWindow() {
      this.infoWindow = new google.maps.InfoWindow();
      this.infoWindowNode = (0, _jquery.default)('#info-window-node');
      return this.infoWindow.addListener('domready', () => {
        return this.populateInfoWindow(this.infoWindow);
      });
    },

    populateInfoWindow(target) {
      const contentArr = target.content.split('||');
      const feature = this.model.features.findBy('id', Number(contentArr[0]));
      let offset = null;
      let content = `<span class='name'>${contentArr[1]}</span>`;

      if (contentArr[3]) {
        content += `<span class='geometric-info'>${contentArr[3]}</span>`;
      }

      if (contentArr[4]) {
        content += `<span class='geometric-info'>${contentArr[4]}</span>`;
      }

      if (this.model.mission.package.hasCatalogItems && contentArr[5]) {
        content += `<span class='geometric-info'>Altitude: ${contentArr[5]}</span>`;
      }

      if (feature.object.getGeometry().getType() === 'Point') {
        content += `<button class='feature-delete' data-feature-id='${contentArr[0]}'>Delete</button>`;
        this.infoWindowNode.addClass('point-info');
        offset = new google.maps.Size(0, -65);
      }

      if (feature.object.getGeometry().getType() === 'Polygon') {
        if (this.model.mission.package.hasCatalogItems || this.model.mission.package.hasRoofReport) {
          content += `<button class='feature-delete' data-feature-id='${contentArr[0]}'>Delete</button>`;
        }

        this.infoWindowNode.removeClass('point-info');
        this.infoWindowNode.css({
          'border-style': 'solid',
          'border-color': feature.color
        });
        offset = new google.maps.Size(0, -10);
      } else {
        this.infoWindowNode.css({
          'border-style': 'none'
        });
      }

      this.infoWindowNode.html(content);
      this.infoWindowNode.find('button.feature-delete').click(event => {
        const feature = this.model.features.findBy('id', Number(event.target.dataset.featureId));
        this.removeFeature(feature);
      });
      target.set('content', this.infoWindowNode[0]);

      if (offset) {
        target.set('pixelOffset', offset);
      }
    },

    loadData() {
      const geoPropertiesPresent = this.model.mission.geo_properties && this.model.mission.geo_properties.features && this.model.mission.geo_properties.features.length;

      if (this.model.mission.map_features.length || geoPropertiesPresent) {
        this.addGeoJson(this.geoJsonConverter.toGeoJson(this.model.mission.map_features) || this.model.mission.geo_properties);
      } else if (this.model.location.latitude && this.model.location.longitude) {
        const latLng = new google.maps.LatLng({
          lat: Number(this.model.location.latitude),
          lng: Number(this.model.location.longitude)
        });
        this.map.setCenter(latLng);
        this.addMarker(latLng, {
          draggable: false
        });
      } else {
        this.defaultLocation();
      }
    },

    saveData() {
      this.map.data.toGeoJson(json => {
        this.set('model.mission.geo_properties', json);
        this.getFeaturePrice(this.selectedFeature);
      });
    },

    defaultLocation() {
      this.clearMap();
      const lat_lng = new google.maps.LatLng(37.2350, -115.8111);
      this.map.setZoom(3);
      this.map.setCenter(lat_lng);
      const searchField = (0, _jquery.default)('.search-field');
      searchField.attr('placeholder', 'Enter Location');
      searchField[0].focus();
    },

    clearMap() {
      this.model.features.forEach(f => this.map.data.remove(f.object));
      this.model.features.clear();
      this.markers.forEach(marker => marker.setMap(null));
      this.markers.clear();
      this.infoWindow.close();
    },

    addGeoJson(json) {
      this.map.data.addGeoJson(json);
      this.fitAllFeatures();
      this.map.setCenter({
        lat: Number(this.model.location.latitude),
        lng: Number(this.model.location.longitude)
      });
      this.addMarker(this.map.getCenter(), {
        draggable: false
      });
    },

    addFeature(feature) {
      const id = this.getNextId();
      const featureIndex = this.getNextIndex(feature.feature_type);
      let feature_object;
      let lat, lng; // Default to map center if needed

      let featureProperties = {
        name: `${feature.defaultName} #${featureIndex}`,
        // Use defaultName if provided
        feature_type: feature.feature_type,
        feature_index: featureIndex,
        notes: '',
        shot_type_attributes: {}
      };

      if (feature.feature_type === 'drawn_polygon') {
        const drawnPolygonOptions = this.featureOptions.DRAWNPOLYGON;
        featureProperties.name = `${drawnPolygonOptions.defaultName} #${featureIndex}`;
        const polygonCoordinates = feature.paths.map(point => ({
          lat: point.lat(),
          lng: point.lng()
        }));
        const dataPolygon = new google.maps.Data.Polygon([polygonCoordinates]);
        feature_object = new google.maps.Data.Feature({
          id: id,
          geometry: dataPolygon,
          properties: { ...featureProperties,
            area: google.maps.geometry.spherical.computeArea(new google.maps.Polygon({
              paths: feature.paths
            }).getPath()).toFixed(1),
            catalog_item_ids: Ember.A([])
          }
        });
      } else if (feature.geometry_type === 'Polygon') {
        lat = this.map.center.lat();
        lng = this.map.center.lng();
        const polygonCoordinates = [new google.maps.LatLng(lat + 0.0003, lng + 0.0003), new google.maps.LatLng(lat + 0.0003, lng - 0.0003), new google.maps.LatLng(lat - 0.0003, lng - 0.0003), new google.maps.LatLng(lat - 0.0003, lng + 0.0003)];
        const dataPolygon = new google.maps.Data.Polygon([polygonCoordinates]);
        feature_object = new google.maps.Data.Feature({
          id: id,
          geometry: dataPolygon,
          properties: { ...featureProperties,
            no_fly_zone: feature.no_fly_zone,
            area: feature.area,
            catalog_item_ids: Ember.A([])
          }
        });
      } else if (feature.geometry_type === 'LineString') {
        lat = this.map.center.lat();
        lng = this.map.center.lng();
        const lineCoordinates = [new google.maps.LatLng(lat + 0.00007, lng + 0.00015), new google.maps.LatLng(lat - 0.00007, lng - 0.00015)];
        const line_object = new google.maps.Data.LineString(lineCoordinates);
        feature_object = new google.maps.Data.Feature({
          id: id,
          geometry: line_object,
          properties: { ...featureProperties,
            lineLength: null,
            catalog_item_ids: Ember.A([])
          }
        });
      } else if (feature.geometry_type === 'Point') {
        const point_object = new google.maps.Data.Point(this.map.getCenter());
        const panoramaShotType = this.model.mission.package.panoramaShotType;

        if (feature.feature_type === 'panorama' && panoramaShotType) {
          featureProperties.shot_type_ids = Ember.A([panoramaShotType.id]);
          featureProperties.shot_type_attributes[panoramaShotType.id] = {
            altitude: panoramaShotType.altitude || 100
          };
        }

        feature_object = new google.maps.Data.Feature({
          id: id,
          geometry: point_object,
          properties: { ...featureProperties,
            catalog_item_ids: Ember.A([])
          }
        });
      }

      this.getFeaturePrice(feature);
      this.map.data.add(feature_object);
    },

    enableFeature(feature) {
      this.disableFeature(this.selectedFeature);
      this.set('selectedFeatureId', feature.id);
      const options = this.selectedFeatureOptions;

      if (feature.object.getProperty('feature_type') === 'panorama' || this.hasCatalogItem(feature.catalog_item_ids)) {
        options.icon = '/assets/images/map/panorama_pin_selected.svg';
      } else {
        options.icon = '/assets/images/map/point_of_interest_selected_pin.svg';
      }

      this.map.data.overrideStyle(feature.object, options);
      this.enableInfoWindow(feature.object);
    },

    concatInfo(original, suffix) {
      if (suffix != undefined && suffix.length) {
        return `${original}||${suffix}`;
      } else {
        return `${original}||`;
      }
    },

    enableInfoWindow(feature) {
      let position;
      const shotTypeAttributes = feature.getProperty('shot_type_attributes');
      const panoAttributes = shotTypeAttributes && shotTypeAttributes[this.model.mission.package.get('panoramaShotType.id')];
      const altitude = panoAttributes && panoAttributes['altitude'];
      let info = this.concatInfo(`${feature.getId()}||${feature.getProperty('name')}`, (0, _formatLength.formatLength)(feature.getProperty('lineLength')));
      info = this.concatInfo(info, (0, _formatArea.formatArea)(feature.getProperty('area')));
      const content = `${this.concatInfo(info, (0, _formatAltitude.formatAltitude)(altitude))}`;

      if (feature.getGeometry().getType() === 'Polygon') {
        position = feature.getGeometry().getAt(0).getAt(0);
        this.infoWindow.setOptions({
          content,
          position,
          pixelOffset: new google.maps.Size(0, 0)
        });
      } else if (feature.getGeometry().getType() === 'LineString') {
        position = feature.getGeometry().getAt(0);
        this.infoWindow.setOptions({
          content,
          position,
          pixelOffset: new google.maps.Size(0, 0)
        });
      } else if (feature.getGeometry().getType() === 'Point') {
        position = feature.getGeometry().get();
        this.infoWindow.setOptions({
          content,
          position,
          pixelOffset: new google.maps.Size(0, -30)
        });
      }

      this.infoWindow.open(this.map);
    },

    setGeometryData(feature) {
      const feature_type = feature.getGeometry().getType();
      const bounds = new google.maps.LatLngBounds();

      if (feature_type === 'Point') {
        return;
      }

      if (feature_type === 'Polygon') {
        feature.setProperty('area', google.maps.geometry.spherical.computeArea(feature.getGeometry().getArray()[0].getArray()).toFixed(1));
        feature.getGeometry().getArray().forEach(path => path.getArray().forEach(latLng => bounds.extend(latLng)));
      } else if (feature_type === 'LineString') {
        const coords = feature.getGeometry().getArray();
        feature.setProperty('lineLength', google.maps.geometry.spherical.computeLength(coords).toFixed(1));
        coords.forEach(latLng => bounds.extend(latLng));
      }

      if (feature.getProperty('bounds') && feature.getProperty('bounds') !== bounds) {
        this.metrics.trackEvent({
          event: 'event',
          category: 'Plan Mission',
          action: 'draw',
          label: `Move/Re-Size ${feature.getProperty('defaultName')}`
        });
      }

      feature.setProperty('bounds', bounds);
      feature.setProperty('center', feature.getProperty('bounds').getCenter());
    },

    lookUpMapOptions(feature) {
      const geometryType = feature.getGeometry().getType();
      const isNoFlyZone = feature.getProperty('type') === 'No Fly Zone' || feature.getProperty('no_fly_zone');
      const feature_type = feature.getProperty('feature_type');
      const hasCatalogItems = this.hasCatalogItem(feature.getProperty('catalog_item_ids'));
      let options = {};

      switch (geometryType) {
        case 'Polygon':
          if (isNoFlyZone) {
            options = this.featureOptions.NOFLYZONE;
          } else {
            options = this.featureOptions.PROPERTYAREA;
          }

          break;

        case 'LineString':
          options = this.featureOptions.LINE;
          break;

        case 'Point':
          if (feature_type === 'panorama' || hasCatalogItems) {
            options = this.featureOptions.PANORAMA;
          } else {
            options = this.featureOptions.POINTOFINTEREST;
          }

      }

      if (geometryType === 'LineString' || geometryType === 'Polygon' && feature_type !== 'no_fly_zone') {
        options.mapColor = options.keyColor = this.getNextColor();
      }

      return options;
    },

    removeFeature(feature) {
      this.map.data.remove(feature.object);
      this.model.features.removeObject(feature);
      this.infoWindow.close();
      this.saveData();
    },

    fitAllFeatures() {
      const bounds = new google.maps.LatLngBounds();
      this.model.features.forEach(feature => {
        this.getFeaturePrice(feature);

        if (feature.object.getGeometry().getType() === 'Polygon') {
          return feature.object.getGeometry().getArray().forEach(path => path.getArray().forEach(latLng => bounds.extend(latLng)));
        } else if (feature.object.getGeometry().getType() === 'Point') {
          return bounds.extend(feature.object.getGeometry().get());
        } else if (feature.object.getGeometry().getType() === 'LineString') {
          return feature.object.getGeometry().getArray().forEach(latLng => bounds.extend(latLng));
        }
      });
      this.map.fitBounds(bounds);
    },

    addMarker(latLng) {
      let {
        icon,
        draggable,
        label
      } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (icon == null) {
        icon = {
          url: '/assets/images/map-icon-pin.svg',
          scaledSize: new google.maps.Size(34, 34)
        };
      }

      if (draggable == null) {
        draggable = false;
      }

      const animation = google.maps.Animation.DROP;
      const marker = new google.maps.Marker({
        map: this.map,
        icon: icon,
        position: latLng,
        draggable: draggable,
        animation: animation,
        label: label,
        crossOnDrag: false
      });
      this.markers.push(marker);
      return marker;
    },

    disableFeature(feature) {
      if (!feature) {
        return false;
      }

      this.infoWindow.close();
      this.set('selectedFeatureId', null);
      const options = this.disabledFeatureOptions;

      if (feature.feature_type === 'panorama' || this.hasCatalogItem(feature.catalog_item_ids)) {
        options.icon = '/assets/images/map/panorama_pin.svg';
      } else {
        options.icon = '/assets/images/map/point_of_interest_pin.svg';
      }

      this.map.data.overrideStyle(feature.object, options);
      return this.validateFeature(feature);
    },

    getFeaturePrice(feature) {
      if (!feature.catalog_item_ids || !this.isCalculateFeaturePrice) {
        return;
      }

      feature.set('isProcessing', true);
      const featurePayload = this.model.mission.geo_properties.features.find(currentFeature => currentFeature.id === feature.id);
      const payload = {
        package_id: this.model.mission.package.id,
        feature: JSON.stringify(featurePayload)
      };

      _jquery.default.ajax({
        url: `${_environment.default.api.host}/v1/clients/invoice_line_items`,
        type: 'POST',
        dataType: 'json',
        headers: this.sessionAccount.headers,
        data: payload
      }).done(data => {
        feature.set('areaOutsideOfRange', data.status === this.AREA_OUTSIDE_OF_RANGE_ERROR);
        feature.set('price', data.amount || 0);
        feature.set('errors', feature.errors.filter(error => error.key !== 'geojson'));
      }).fail(response => {
        let newErrors = feature.errors.filter(error => error.key !== 'geojson');
        let errorMessage = response.responseJSON.message;
        if (errorMessage.includes('LinearRing failed ring test')) errorMessage = 'Polygon has an invalid shape. Please fix it before continuing. ' + 'This may be caused by a polygon overlapping with itself';
        newErrors.addObject({
          key: 'geojson',
          validation: [errorMessage]
        });
        feature.set('errors', newErrors);
      }).always(() => {
        feature.set('isProcessing', false);
      });
    },

    updateFeature() {
      if (this.selectedFeature && this.validateFeature(this.selectedFeature)) {
        this.disableFeature(this.selectedFeature); // there validation calls twice

        this.saveData();
      }
    },

    validateFeature(feature) {
      if (!feature || !feature.object) {
        return false;
      }

      this.setDefaultNameIfEmpty(feature);

      if (!this.model.mission.package.hasCatalogItems) {
        return true;
      }

      let featureChangeSet = this.getFeatureChangeSet(feature);

      if (featureChangeSet) {
        featureChangeSet.validate();
        let newErrors = featureChangeSet.get('errors');
        const geojsonError = feature.errors.find(error => error.key === 'geojson');
        if (geojsonError) newErrors.addObject(geojsonError);
        feature.set('errors', newErrors);
        return feature.get('errors').length === 0;
      } else {
        return true;
      }
    },

    setDefaultNameIfEmpty(feature) {
      const currentName = feature?.name?.trim() || feature?.object?.getProperty('name')?.trim();

      if (!currentName || currentName.length === 0) {
        const defaultName = `${feature.defaultName} #${feature.feature_index}`;
        feature.set('name', defaultName);
        feature.object.setProperty('name', defaultName);
      }
    },

    getFeatureChangeSet(feature) {
      const geometryType = feature.object.getGeometry().getType();
      const featureType = feature.object.getProperty('feature_type');
      let featureValidator = null;

      if (geometryType === 'Point') {
        if (featureType === 'panorama') {
          featureValidator = _mapPanorama.default;
        } else {
          featureValidator = _mapPoint.default;
        }
      } else if (geometryType === 'Polygon') {
        featureValidator = _mapPolygon.default;
        const filtered_catalog_items = this.model.mission.package.get('catalog_items').filter(catalog_item => catalog_item.has_pano_shot_type);

        if (filtered_catalog_items.length === this.model.mission.package.get('catalog_items').length) {
          delete featureValidator.catalog_item_ids;
        }
      }

      return featureValidator && new _emberChangeset.default(feature, (0, _emberChangesetValidations.default)(featureValidator), featureValidator);
    },

    validateFeatures() {
      const polygonsCount = this.model.features.filter(feature => feature.object.getGeometry().getType() === 'Polygon').length;
      const panosCount = this.model.features.filter(feature => feature.feature_type === 'panorama').length;
      const pointOfInterestCount = this.model.features.filter(feature => feature.feature_type === 'point_of_interest').length;
      let newErrors = this.errors.filter(error => !['absent_polygon', 'absent_panorama'].includes(error.key));

      if ((this.model.mission.package.hasCatalogItems || this.model.mission.package.hasRoofReport) && polygonsCount === 0) {
        newErrors.addObject({
          key: 'absent_polygon',
          message: 'You must draw at least one property area before moving to the next step.'
        });
      }

      if (this.model.mission.package.panoramaShotType && panosCount === 0 && pointOfInterestCount === 0 && polygonsCount === 0) {
        newErrors.addObject({
          key: 'absent_panorama',
          message: 'The Package Includes a Panorama. Please use panorama tool to indicate the capture location for the panorama before you move on to the next step.',
          link: {
            title: 'Panorama Guide',
            href: 'https://dbcustomers.zendesk.com/hc/en-us/articles/115002138791-Placing-an-Order'
          }
        });
      }

      this.set('errors', newErrors);
    },

    getNextColor() {
      this.set('colorIndex', this.colorIndex + 1);
      return this.featureColors[this.colorIndex % 5];
    },

    getNextId() {
      if (this.model.features.length) {
        const featureIds = this.model.features.map(feature => parseInt(feature.id));
        return Math.max(...featureIds) + 1;
      } else {
        return 1;
      }
    },

    getNextIndex(feature_type) {
      const filteredFeatures = this.model.features.filterBy('feature_type', feature_type);

      if (filteredFeatures.length) {
        const featureIds = filteredFeatures.map(feature => parseInt(feature.feature_index));
        return Math.max(...featureIds) + 1;
      } else {
        return 1;
      }
    },

    hasCatalogItem(catalog_item_ids) {
      if (!catalog_item_ids) return false;
      const catalog_item = this.model.mission.package.get('catalog_items').find(item => catalog_item_ids.includes(item.id));
      return catalog_item?.has_pano_shot_type;
    },

    changeToPanoramaIcon(selectedFeature) {
      const feature = this.map.data.getFeatureById(selectedFeature.id);
      this.map.data.overrideStyle(feature, {
        icon: {
          url: "/assets/images/map/panorama_pin_selected.svg"
        }
      });
    },

    changeToPointIcon(selectedFeature) {
      const feature = this.map.data.getFeatureById(selectedFeature.id);
      this.map.data.overrideStyle(feature, {
        icon: {
          url: "/assets/images/map/point_of_interest_pin.svg"
        }
      });
    }

  });
  var _default = GoogleMapFeaturesMixin;
  _exports.default = _default;
});