define("clients/components/order-flow/site-details", ["exports", "clients/mixins/google-map-features-mixin", "jquery"], function (_exports, _googleMapFeaturesMixin, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_googleMapFeaturesMixin.default, {
    sessionAccount: Ember.inject.service(),
    isClientUpload: Ember.computed.equal('model.orderFlow', 'clientUpload'),
    hasLocation: Ember.computed.or('model.location.address', 'model.location.latitude'),
    hasGeoLocation: Ember.computed.and('model.location.longitude', 'model.location.latitude'),
    classNames: ['order-flow-information', 'site-details'],
    DEFAULT_ZOOM: 18,
    activeTab: 'missionDetails',
    points: [],
    polygon: null,
    isDrawing: false,
    isDragging: false,
    isHoveringOverlay: false,
    isHoveringMap: true,
    markers: [],
    canFinishPolygon: Ember.computed.gte('points.length', 3),

    handleFinishPolygonClick(canFinishPolygon, event) {
      if (!canFinishPolygon) {
        event.preventDefault();
        return;
      }

      this.send('finishPolygon');
    },

    initMap() {
      const mapOptions = this.setupMapOptions();
      this.initializeMap(mapOptions);
      this.setupListeners();
      this.setupInfoWindow();
      this.loadData();
      this.setupDrawingListeners();
      google.maps.event.addListenerOnce(this.map, 'idle', () => {
        this.attachHoverListenersToMapControls();
      });
    },

    setupMapOptions() {
      return {
        disableDoubleClickZoom: false,
        draggable: true,
        fullscreenControl: false,
        mapTypeControl: true,
        mapTypeControlOptions: {
          position: google.maps.ControlPosition.TOP_RIGHT
        },
        mapTypeId: google.maps.MapTypeId.HYBRID,
        panControl: false,
        scaleControl: false,
        streetViewControl: false,
        styles: this.mapStyles,
        tilt: 0,
        zoom: this.DEFAULT_ZOOM,
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_TOP
        }
      };
    },

    initializeMap(mapOptions) {
      if (!this.map) {
        this.map = new google.maps.Map((0, _jquery.default)('#map_canvas')[0], mapOptions);
      }
    },

    setupDrawingListeners() {
      google.maps.event.addListener(this.map, 'click', event => {
        if (this.isDrawing) {
          this.addPoint(event.latLng);
        }
      });
    },

    attachHoverListenersToMapControls() {
      const observer = new MutationObserver(() => {
        const zoomControls = document.querySelectorAll('.gmnoprint');
        const mapTypeToggle = document.querySelector('.gm-style-mtc');
        const mapErrorButton = document.querySelectorAll('.gm-style-cc');
        const bottomRightButton = document.querySelector('.gm-tilt.gm-control-active');
        const mapDataButtons = document.querySelectorAll('.gmnoprint');
        const controls = [...zoomControls, ...mapErrorButton, mapTypeToggle, bottomRightButton, ...mapDataButtons].filter(Boolean);
        controls.forEach(control => {
          if (!control.getAttribute('data-hover-listener-attached')) {
            control.addEventListener('mouseenter', () => {
              this.set('isHoveringMapControl', true);
              this.clearPreviewPolyline();
            });
            control.addEventListener('mouseleave', () => {
              this.set('isHoveringMapControl', false);
            });
            control.setAttribute('data-hover-listener-attached', 'true');
          }
        });
      });
      const mapContainer = document.querySelector('#map_canvas').parentNode;
      observer.observe(mapContainer, {
        childList: true,
        subtree: true
      });
    },

    setupMarkerEventListeners(marker) {
      google.maps.event.addListener(marker, 'click', () => {
        this.send('undoLastPoint');
      });
      google.maps.event.addListener(marker, 'mousedown', () => {
        this.isDragging = true;
      });
      google.maps.event.addListener(marker, 'drag', event => {
        if (this.isDragging) {
          const index = this.markers.indexOf(marker);

          if (index > -1) {
            this.points[index] = event.latLng;
            this.updatePolygonPath();
            this.clearPreviewPolyline();
          }
        }
      });
      google.maps.event.addListener(this.map, 'mousemove', event => {
        if (this.isDrawing && this.points.length > 0 && !this.isHoveringFinishPolygon && !this.isHoveringMapControl) {
          this.drawPreviewLine(this.points[this.points.length - 1], event.latLng);
        } else {
          this.clearPreviewPolyline();
        }
      });
      google.maps.event.addListener(marker, 'dragend', () => {
        this.isDragging = false;
      });
    },

    drawPreviewLine(startPoint, endPoint) {
      if (this.previewPolyline) {
        this.previewPolyline.setMap(null);
      }

      const path = [startPoint, endPoint];

      if (this.points.length > 1) {
        path.push(this.points[0]);
      }

      this.previewPolyline = new google.maps.Polyline({
        path: path,
        strokeColor: '#4B3EC1',
        strokeOpacity: 1,
        strokeWeight: 2,
        clickable: false,
        map: this.map
      });
    },

    clearPreviewPolyline() {
      if (this.previewPolyline) {
        this.previewPolyline.setMap(null);
        this.previewPolyline = null;
      }
    },

    updatePolygonPath() {
      if (this.polygon) {
        this.polygon.setPaths(this.points);
      }
    },

    addPoint(latLng) {
      this.updateMarkerStyles();
      this.points.push(latLng);
      this.notifyPropertyChange('points');
      const marker = this.createMarker(latLng);
      this.setupMarkerEventListeners(marker);
      this.markers.push(marker);
      this.fullRedrawPolygonPath();
    },

    createMarker(latLng) {
      return new google.maps.Marker({
        position: latLng,
        map: this.map,
        draggable: true,
        cursor: 'grab',
        draggableCursor: 'crosshair',
        raiseOnDrag: false,
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 3,
          fillColor: '#FF0000',
          fillOpacity: 1,
          strokeColor: '#FF0000',
          strokeWeight: 2
        }
      });
    },

    updateMarkerStyles() {
      this.markers.forEach((marker, index) => {
        marker.setIcon({
          path: google.maps.SymbolPath.CIRCLE,
          scale: 3,
          fillColor: index === 0 && this.points.length > 1 ? '#00FF00' : '#FFFFFF',
          fillOpacity: 1,
          strokeColor: index === 0 && this.points.length > 1 ? '#00FF00' : '#7363FB',
          strokeWeight: 1
        });
        google.maps.event.clearListeners(marker, 'click');

        if (index === 0) {
          google.maps.event.addListener(marker, 'click', () => {
            if (this.points.length === 1) {
              this.send('undoLastPoint');
            } else if (this.points.length < 3) {
              return;
            } else {
              this.send('finishPolygon');
            }
          });
        }
      });
    },

    fullRedrawPolygonPath() {
      if (this.polygon) {
        this.polygon.setMap(null);
      }

      this.polygon = new google.maps.Polygon({
        paths: this.points.slice(),
        strokeColor: '#4B3EC1',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#7363FB',
        fillOpacity: 0.35,
        clickable: false
      });
      this.polygon.setMap(this.map);
    },

    finishPolygon() {
      if (this.points.length < 3) return;
      const featureOptions = this.generateFeatureOptions();
      this.addPolygonFeature(featureOptions);
      this.resetDrawing();

      if (this.map) {
        this.map.setOptions({
          draggableCursor: null
        });
      }
    },

    generateFeatureOptions() {
      return {
        paths: this.points.slice(),
        strokeColor: '#4B3EC1',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#7363FB',
        fillOpacity: 0.35,
        name: 'Drawn Polygon',
        feature_type: 'drawn_polygon'
      };
    },

    addPolygonFeature(featureOptions) {
      this.addFeature(featureOptions);
    },

    resetDrawing() {
      this.resetDrawingState();
      this.clearMarkersAndPolygon();
      this.clearPreviewPolyline();
    },

    resetDrawingState() {
      this.set('isDrawing', false);
      this.set('points', []);
    },

    clearMarkersAndPolygon() {
      if (this.polygon) this.polygon.setMap(null);
      this.polygon = null;
      this.markers.forEach(marker => marker.setMap(null));
      this.markers = [];
    },

    clearDrawing() {
      this.set('points', []);

      if (this.polygon) {
        this.polygon.setMap(null);
        this.polygon = null;
      }

      this.clearPreviewPolyline();
      this.markers.forEach(marker => {
        if (marker) {
          marker.setMap(null);
        }
      });
      this.set('markers', []);
    },

    removeLastPoint() {
      this.points.pop();
      this.notifyPropertyChange('points');
      const marker = this.markers.pop();

      if (marker) {
        marker.setMap(null);
      }
    },

    updateLastMarkerStyle() {
      if (this.markers.length > 0) {
        const lastMarker = this.markers[this.markers.length - 1];
        lastMarker.setIcon({
          path: google.maps.SymbolPath.CIRCLE,
          scale: 3,
          fillColor: '#FF0000',
          fillOpacity: 1,
          strokeColor: '#FF0000',
          strokeWeight: 2
        });
        google.maps.event.addListener(lastMarker, 'click', () => {
          this.send('undoLastPoint');
        });
      }
    },

    redrawPolygon() {
      if (this.points.length === 0) {
        this.fullRedrawPolygonPath();
        return;
      }

      this.fullRedrawPolygonPath();
    },

    actions: {
      switchToDrawPolygon() {
        this.toggleProperty('isDrawing');
        this.set('points', []);

        if (this.polygon) {
          this.polygon.setMap(null);
        }

        this.polygon = null;
        this.markers.forEach(marker => marker.setMap(null));
        this.markers = [];

        if (this.map) {
          if (this.isDrawing) {
            this.map.setOptions({
              draggableCursor: 'crosshair'
            });
          } else {
            this.map.setOptions({
              draggableCursor: 'grab'
            });
          }
        }
      },

      setHoveringMap(isHovering) {
        this.set('isHoveringMap', isHovering);

        if (!isHovering) {
          this.clearPreviewPolyline();
        }
      },

      setHoverState(isHovering) {
        this.set('isHoveringOverlay', isHovering);

        if (isHovering) {
          this.clearPreviewPolyline();
        }
      },

      finishPolygon() {
        this.finishPolygon();
      },

      undoLastPoint() {
        if (this.points.length > 0) {
          this.removeLastPoint();
          this.updateLastMarkerStyle();
          this.redrawPolygon();
        }
      },

      changeToPanoramaIcon(selectedFeature) {
        this.changeToPanoramaIcon(selectedFeature);
      },

      changeToPointIcon(selectedFeature) {
        this.changeToPointIcon(selectedFeature);
      },

      increaseOrderStep() {
        const isValidCurrentFeature = !this.selectedFeature || this.validateFeature(this.selectedFeature);
        this.validateFeatures();

        if (isValidCurrentFeature && this.errors.length === 0) {
          this.increaseOrderStepAction();
        }
      },

      activateTab(tabName) {
        this.set('activeTab', tabName);
      },

      addPanorama() {
        this.addFeature(this.featureOptions.PANORAMA);
      },

      addPointOfInterest() {
        this.addFeature(this.featureOptions.POINTOFINTEREST);
      },

      addPolygon() {
        this.addFeature(this.featureOptions.PROPERTYAREA);
      },

      updatePolygon() {
        this.updateFeature();
      },

      removePolygon(feature) {
        this.removeFeature(feature);
      },

      clearDrawing() {
        this.clearDrawing();
      }

    }
  });

  _exports.default = _default;
});