define("clients/routes/missions/show", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "clients/mixins/css-class-namespace", "clients/mixins/mission-share", "clients/data/sample_mission_list", "clients/data/sample_mission_ids"], function (_exports, _authenticatedRouteMixin, _cssClassNamespace, _missionShare, _sample_mission_list, _sample_mission_ids) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MissionsShowRoute;
  MissionsShowRoute = Ember.Route.extend(_authenticatedRouteMixin.default, _missionShare.default, _cssClassNamespace.default, {
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    metrics: Ember.inject.service(),
    model: function (params) {
      var mission, sampleMissionId;

      if (!_sample_mission_ids.default.includes(params.mission_id)) {
        return Ember.RSVP.hash({
          mission: this.get('store').findRecord('mission', params.mission_id).then(function (response) {
            return response;
          }, function (_this) {
            return function (error) {
              alert('Sorry, an error occurred while loading this mission: ' + error);
              console.log('Error loading mission: ' + error);
              return _this.replaceWith('fourOhFour', {});
            };
          }(this)),
          client: this.get('sessionAccount.account'),
          currencies: this.get('store').findAll('currency')
        });
      } else {
        sampleMissionId = "";
        mission = this.get('store').peekRecord('mission', params.mission_id);
        Object.keys(_sample_mission_list.default).forEach(function (_this) {
          return function (sampleName) {
            if (_sample_mission_list.default[sampleName].data.id === params.mission_id) {
              sampleMissionId = sampleName;

              if (!mission) {
                _this.get('store').pushPayload(_sample_mission_list.default[sampleName]);

                return mission = _this.get('store').peekRecord('mission', params.mission_id);
              }
            }
          };
        }(this));
        return {
          mission: mission,
          client: this.get('sessionAccount.account'),
          sampleMission: true,
          sampleMissionId: sampleMissionId
        };
      }
    },
    setupController: function (controller, model) {
      this._super(controller, model);

      if (!model.mission) {
        console.log('Error: model.mission not found');
      }

      this.controllerFor('application').set('showNavBar', true);
      this.controllerFor('application').set('minimizedSidebar', false);
      this.controllerFor('application').set('showInsightsBanner', false);
      this.controllerFor('application').set('canManageInsightsUsers', this.sessionAccount.canManageInsightsUsers);

      if (model.client.is_insights_buildings) {
        this.get('session').set('returnToInsightsLink', model.mission.insightsLink);
      }

      if (this.get('sessionAccount.adminMode')) {
        this.controllerFor('application').set('adminMode', true);
      }

      if (model.mission.get('status') === 'awaiting_payment') {
        controller.set('showModal', true);
      }

      if (model.mission.get('assetsDelivered') === true) {
        controller.set('activeTab', 'editedAssets');
      } else if (model.mission.get('assetsUploaded') === true) {
        controller.set('activeTab', 'rawAssets');
      }

      this.controllerFor('application').set('showQuickOrder', true);

      if (!model.sampleMission) {
        model.mission.loadInvoiceLineItems();
        model.mission.loadImages();
      }

      if (!this.get('sessionAccount.adminMode')) {
        return this.get('metrics').trackEvent({
          objectId: model.mission.id,
          objectType: 'mission',
          category: 'Mission',
          action: 'view',
          label: "Viewed Mission Dashboard"
        });
      }
    },
    actions: {
      willTransition: function () {
        return this.controllerFor('application').set('showQuickOrder', false);
      },
      downloadAsset: function (asset, new_window) {
        var type;

        if (asset.constructor.modelName) {
          type = 'video';
        } else {
          type = 'image';
        }

        this.get('metrics').trackEvent({
          event: 'event',
          objectId: asset.id,
          objectType: type,
          category: 'Mission',
          action: 'click',
          label: 'Download Asset'
        });
        return window.location = asset.get('downloadUrl');
      },
      edit_mission_plan: function (mission) {
        this.get('metrics').trackEvent({
          event: 'event',
          objectId: mission.id,
          objectType: 'mission',
          category: 'Mission',
          action: 'click',
          label: 'Edit Mission Plan/Map'
        });
        return this.transitionTo('missions.edit', mission.id);
      },
      viewVideo: function (video) {
        return video.set('viewing', true);
      },
      reloadModel: function () {
        return this.refresh();
      }
    }
  });
  var _default = MissionsShowRoute;
  _exports.default = _default;
});